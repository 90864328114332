<template>
    <!-- 销售价格 -->
    <div class="selling-price-child">
        <div class="child-header">
            <h1>序号</h1>
            <h1>
                {{ currentData.name }}
            </h1>
            <h1>
                <b></b>
                得分
                <i v-if="!viewOnly" @click="addList" class="el-icon-plus"></i>
                <b v-else></b>
            </h1>
        </div>
        <div class="content-box">
            <div class="selling-price-child-content" v-for="(item, index) in childList" :key="index">
                <div class="list-left">
                    {{ index + 1 }}
                </div>
                <div class="section-select">
                    <div class="section-box">
                        区间：
                        <el-select
                            :disabled="index !== childList.length - 1 || viewOnly"
                            @change="changeSelect(item)"
                            v-model="item.type"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="items in sectionList"
                                :key="items.type"
                                :label="items.label"
                                :value="items.type"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="item.type === '0' || item.type === '3'" class="input-floor-upper">
                        <h2>
                            低于当期企业销售指导价的 <el-input
                                :disabled="index !== childList.length - 1 || viewOnly"
                                v-model="item.floor"
                            ></el-input>%
                        </h2>
                        <h2>~</h2>
                        <h2>
                            <el-input
                                :disabled="index !== childList.length - 1 || viewOnly"
                                v-model="item.upper"
                            ></el-input>%
                        </h2>
                    </div>
                    <div v-else-if="item.type === '1' || item.type === '2'" class="input-floor-upper">
                        <h2>
                            低于当期企业销售指导价的<el-input
                                :disabled="index !== childList.length - 1 || viewOnly"
                                v-model="item.floor"
                            ></el-input>%
                        </h2>
                    </div>
                    <div v-else-if="item.type === '4' || item.type === '5'" class="input-floor-upper">
                        <h2>
                            低于当期企业销售指导价的<el-input
                                :disabled="index !== childList.length - 1 || viewOnly"
                                v-model="item.upper"
                            ></el-input>%
                        </h2>
                    </div>
                </div>
                <div class="list-right">
                    <i style="color:#fff"></i>
                    <el-input
                        :disabled="index !== childList.length - 1 || viewOnly"
                        v-model="item.score"
                        @change="changeScore(item.score)"
                    ></el-input>
                    <i
                        v-if="index === childList.length - 1"
                        :class="viewOnly ? ' ' : 'el-icon-delete'"
                        @click="deleteList"
                    ></i>
                    <i :class="viewOnly ? ' ' : 'el-icon-delete'" style="color:#fff" v-else></i>
                </div>
            </div>
        </div>
        <div class="message-bottom-button" v-if="!viewOnly">
            <el-button type="primary" @click="saveList">
                保存
            </el-button>
            <el-button @click="closeWindow">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            childList: [],
            sectionList: [
                {
                    type: '0',
                    label: '区间',
                }, {
                    type: '1',
                    label: '大于',
                }, {
                    type: '2',
                    label: '大于等于',
                }, {
                    type: '4',
                    label: '小于等于',
                }, {
                    type: '5',
                    label: '小于',
                },
            ],
            sectionData: '',
        };
    },
    props: {
        // 当前点击数据
        currentData: {
            type: Object,
        },
        // 当前期号
        runTimeDate: {
            type: Object,
        },
        // 是否只读
        viewOnly: {
            type: [String, Boolean],
        },
    },
    mounted() {
        this.getCreditScore();
    },
    methods: {
        // 删除列
        deleteList() {
            this.childList.pop();
        },
        // 获取已配置项
        getCreditScore() {
            this.$axios
                .get(`/interfaceApi/sale/credit_config/get_credit_score/${this.runTimeDate.AutoCodeValue}/${this.currentData.code}`)
                .then(res => {
                    res.forEach(item => {
                        item.type = String(item.type);
                    });
                    this.childList = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换区间
        changeSelect(item) {
            if (item.type === '1' || item.type === '1') {
                item.floor = '';
                item.upper = '';
            } else if (item.type === '4' || item.type === '5') {
                item.floor = '';
                item.upper = '';
            } else {
                item.floor = '';
                item.upper = '';
            }
        },
        // 添加列
        addList() {
            const leng = this.childList[this.childList.length - 1];
            if (this.childList.length === 0) {
                const obj = {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: '0',
                    floor: '',
                    upper: '',
                    score: 0,
                };
                this.childList.push(obj);
            } else if (!leng.floor && (leng.type === '1' || leng.type === '2')) {
                this.$message.warning('请输入销售价格');
            } else if (!leng.upper && (leng.type === '4' || leng.type === '5')) {
                this.$message.warning('请输入销售价格');
            } else if ((!leng.floor || !leng.upper) && leng.type === '0') {
                this.$message.warning('请输入销售价格');
            } else if (!leng.score) {
                this.$message.warning('请打分');
            } else if (leng.score < 0 || leng.score > 10) {
                this.$message.warning('请输入正确分数1-10');
            } else {
                const obj = {
                    item_id: this.currentData.id,
                    credit_no: this.runTimeDate.AutoCodeValue,
                    item_code: this.currentData.code,
                    type: '0',
                    floor: '',
                    upper: '',
                    score: 0,
                };
                this.childList.push(obj);
            }
        },
        changeScore(score) {
            if (score > 10 || score < 1) {
                this.$message.error('得分必须在1-10之间');
            }
        },
        // 保存
        saveList() {
            const arr = this.childList.filter(item => item.score > 10 || item.score < 1);
            if (arr.length > 0) {
                this.$message.error('得分必须在1-10之间');
                return;
            }
            this.$emit('saveList', this.childList);
        },
        closeWindow() {
            this.$emit('closeWindow');
        },
    },
};

</script>
<style lang='stylus'>
// 销售价格
.selling-price-child
    position relative
    height 100%
    .child-header
        width 100%
        display flex
        height .45rem
        position absolute
        top 0
        z-index 2
        h1
            flex 1
            display flex
            align-items center
            justify-content center
            border-bottom 1px solid #ccc
            border-right 1px solid #ccc
            background #d9e2f3
            color #092d8e
            &:nth-child(2)
                flex 3
            &:nth-child(3)
                flex 1
                display flex
                justify-content space-between
                b
                    margin-left .4rem
                i
                    margin-right .4rem
                    border 1px solid #092d8e
                    border-radius .06rem
                    cursor pointer
            &:last-child
                border-right none
    .content-box
        width 100%
        padding-top: .45rem;
        height: 5rem;
        overflow: auto;
        height calc(100% - .85rem)
    .selling-price-child-content
        display flex
        width 100%
        height .45rem
        border-bottom 1px solid #ccc
        align-items center
        justify-content center
        .el-input__suffix
            right .3rem
        .list-left
            flex 1
            height 100%
            width .5rem
            text-align center
            line-height .45rem
            border-right 1px solid #ccc
        .section-select
            flex 3
            display flex
            align-items center
            .section-box
                display flex
                font-size .16rem
                margin-left .16rem
                flex 1
                font-size .16rem
                height .28rem
                line-height .28rem
                .el-select
                    width 1rem
                    input
                        padding 0 0 0 .1rem
                        font-size .16rem
                        height .28rem
                        line-height .28rem
            .input-floor-upper
                flex 2
                display flex
                width 100%
                flex-direction row
                align-items center
                border-left 1px solid #ccc
                height .45rem
                padding-left .16rem
                h2
                    display flex
                    height 100%
                    font-size .16rem
                    align-items center
                    justify-content center
                    &:nth-child(2)
                        margin 0 .1rem
                    .el-input
                        width .6rem
                        height .28rem
                        line-height .28rem
                        input
                            width 100%
                            padding 0
                            text-align center
                            font-size .16rem
                            height .28rem
                            line-height .28rem
        .list-right
            flex 1
            display flex
            align-items center
            justify-content space-between
            border-left 1px solid #ccc
            .el-input
                width .6rem
                height .45rem
                display flex
                align-items center
                justify-content center
                margin-right .16rem
                input
                    height .28rem
                    line-height .28rem
                    font-size .16rem
                    text-align center
                    padding 0
            i
                margin-right .4rem
                color #DD2222
                cursor pointer
    .message-bottom-button
        width 100%
        display flex
        justify-content center
        margin .2rem 0
        position absolute
        bottom 0
        left 0
        button
            width 2.5rem
            &:last-child
                margin-left 1rem
</style>